































































import { Component, Mixins } from 'vue-property-decorator';
import TableComponent from '@/mixins/table-component';
import SubSystemComponent from '@/mixins/sub-system-component';
import { AlarmEventEntityModel, AlarmEventQueryModel, AlarmEventTableColumns } from '@/entity-model/alarm-event-entity';
import AlarmEventService from '@/service/alarm-event';
import AlarmEventDrawer from './alarm-event-drawer.vue';
import { dateFormat } from '@/filter';
import { SubSystemType, ViewModeType } from '@/model/enum';
import FormDialogComponent from '@/mixins/form-dialog-component';

@Component({
    components: {
        'alarm-event-drawer': AlarmEventDrawer
    }
})
export default class AlarmEventListComponent extends Mixins(TableComponent, SubSystemComponent) {
    drawerVisible: boolean = false;
    currentEventId: string = null;
    currentEventTitle: string = null;
    currentEventIndex: number = null;
    pageTitle: string = null;
    pagePath: string = '/';
    pageSubTitle: string = null;

    initPage() {
        let queryModel = null;
        queryModel = new AlarmEventQueryModel(this.SubSystemName);
        switch (this.SubSystemName) {
            case SubSystemType.ALARM:
                this.pageTitle = '首页';
                this.pageSubTitle = '正在告警';
                this.pagePath = '/';
                queryModel.active = true;
                break;
            case SubSystemType.ALARM_HISTORY:
                this.pageTitle = '首页';
                this.pageSubTitle = '历史告警';
                this.pagePath = '/';
                queryModel.active = false;
                break;
            case SubSystemType.PARKING:
                this.pageTitle = '停车场管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/parking-index';
                break;
            case SubSystemType.FIRE:
                this.pageTitle = '消防管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/fire-index';
                break;
            case SubSystemType.CAMERA:
                this.pageTitle = '监控管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/camera-index';
                break;
            case SubSystemType.ENVIROMENT:
                this.pageTitle = '环境管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/env-index';
                break;
            case SubSystemType.ENERGY:
                this.pageTitle = '能源管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/energy-index';
                break;
            case SubSystemType.BA:
                this.pageTitle = '楼宇自控管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/ba-index';
                break;
            case SubSystemType.CUSTOMER_FLOW:
                this.pageTitle = '客流系统';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/customer-flow-index';
                break;
            case SubSystemType.ELEVATOR:
                this.pageTitle = '电梯管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/elevator-index';
                break;
            case SubSystemType.LIGHT:
                this.pageTitle = '照明管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/light-index';
                break;
            case SubSystemType.ACCESS_CONTROL:
                this.pageTitle = '门禁管理';
                this.pageSubTitle = null;
                this.pagePath = '/dashboard/access-control-index';
                break;
        }
        this.initTable({ listFunc: AlarmEventService.query, queryModel, tableColumns: AlarmEventTableColumns });
        this.getList();
    }

    detailClick(model: any, index: number) {
        this.drawerVisible = true;
        this.currentEventId = model.id;
        this.currentEventTitle = model.eventName;
        this.currentEventIndex = index;
    }

    handleClick(model: any, isBatch: boolean) {
        let selectModel = null;
        let dialogTitle = null;
        if (isBatch) {
            selectModel = new AlarmEventEntityModel(this.selectedRowKeys);
            dialogTitle = '批量事件处理';
        } else {
            selectModel = new AlarmEventEntityModel([model.id]);
            dialogTitle = '事件处理';
        }
        (this.$refs.handleFormDialog as FormDialogComponent<AlarmEventEntityModel>).dialogOpen(selectModel, AlarmEventService, ViewModeType.UPDATE);
        (this.$refs.handleFormDialog as FormDialogComponent<AlarmEventEntityModel>).dialogTitle = dialogTitle;
    }
    onDrawerClose() {
        this.drawerVisible = false;
        this.currentEventId = null;
    }
    eventChange(step: number) {
        const nextIndex = this.currentEventIndex + step;
        if (nextIndex < 0) {
            this.showMessageInfo('已经是第一个了');
            return;
        }
        if (nextIndex > this.listData.length - 1) {
            this.showMessageInfo('已经是最后一个了');
            return;
        }
        this.detailClick(this.listData[nextIndex], nextIndex);
    }
    // exportClick() {
    //     this.startFullScreenLoading('正在导出Excel...');
    //     AlarmEventService.exportExcel(this.queryModel as AlarmEventQueryModel).then(res => {
    //         this.showMessageSuccess('成功导出Excel');
    //     }).finally(() => {
    //         this.stopFullScreenLoading();
    //     });
    // }
    // eventHandled() {
    //     // this.onDrawerClose();
    //     // this.currentEventId = '';
    //     // this.page = 1;
    //     this.getList();
    // }
}

