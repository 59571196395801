import { SubSystemQueryModel } from '@/model/query-model';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { Moment } from 'moment';
import { eventLevelList } from '@/filter/event-level';
import CommonService from '@/service/common';
import { EnableType, PropertyEntityType } from '@/model/enum';
import { BaseModel } from '@/model/base-model';
import { FormControl, FormControlOptionModel, FormControlTextModel, FormControlType } from '@/model/form-control';
import { enableList } from '@/filter/enable';
import { dateFormat } from '@/filter';

export const AlarmEventTableColumns = [
    {
        title: '告警时间',
        dataIndex: 'createdTime',
        customRender: (text) => {
            return dateFormat(text);
        }
    },
    {
        title: '告警名称',
        dataIndex: 'eventName',
        scopedSlots: { customRender: 'name' }
    },
    {
        title: '告警级别',
        dataIndex: 'gradeDesc'
    },
    {
        title: '告警类型',
        dataIndex: 'levelDesc'
    },
    {
        title: '告警描述',
        dataIndex: 'remark'
    },
    {
        title: '告警位置',
        dataIndex: 'locationName'
    },
    {
        title: '处理状态',
        dataIndex: 'statusDesc'
    },
    {
        title: '操作',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
    }
];

export class AlarmEventEntityModel extends BaseModel {
    constructor(ids: Array<string>) {
        super();
        this.ids = ids;
    }
    ids: Array<string> = undefined;

    @FormControl({
        label: '处理意见',
        type: FormControlType.TEXT_AREA,
        required: true
    } as FormControlTextModel)
    comments: string = undefined;

    @FormControl({
        label: '是否恢复',
        type: FormControlType.RADIO_GROUP,
        options: enableList,
        required: true
    } as FormControlOptionModel)
    recover: EnableType = undefined;

    toService(): any {
        return {
            ids: this.ids,
            comments: this.comments,
            recover: !!this.recover
        };
    }
}

export class AlarmEventQueryModel extends SubSystemQueryModel {
    @QueryControl({
        label: '告警级别',
        type: QueryControlType.SELECT,
        optionsPromise: CommonService.getPropertyOptions,
        optionsPromiseParam: PropertyEntityType.EVENT_GRADE,
        hasAllOption: true,
        index: 11
    })
    grade: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '告警类型',
        type: QueryControlType.SELECT,
        options: eventLevelList,
        hasAllOption: true,
        index: 12
    })
    level: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '处理状态',
        type: QueryControlType.SELECT,
        options: [
            { value: 'YCL', name: '已处理' },
            { value: 'WCL', name: '未处理' }
        ],
        hasAllOption: true,
        index: 13
    })
    status: string = JTL.CONSTANT.ALL_OPTION.value;

    @QueryControl({
        label: '告警时间',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        index: 14
    })
    dateRange: Array<Moment> = JTL.CONSTANT.TODAY();

    @QueryControl({
        label: '告警名称',
        placeholder: '请输入告警名称',
        type: QueryControlType.TEXT,
        index: 15
    })
    eventName: string = undefined;

    /**
     * true: 正在报警
     * false: 历史报警
     */
    active: boolean = undefined;

    toService() {
        // const data = super.getParams();
        // data.params.startTime = this.dateRange[0].toDate().getTime();
        // data.params.endTime = this.dateRange[1].toDate().getTime();
        // delete data.params.dateRange;
        // delete data.params.subSystemType;
        // return data;
        return {
            params: {
                endTime: this.dateRange[1].toDate().getTime(),
                grades: this.grade ? [this.grade] : undefined,
                levels: this.level ? [this.level] : undefined,
                startTime: this.dateRange[0].toDate().getTime(),
                status: this.status ? [this.status] : undefined,
                active: this.active,
                eventName: this.eventName
            }
        };
    }
}
