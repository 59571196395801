
import { AlarmEventQueryModel } from '@/entity-model/alarm-event-entity';
import { SubSystemType } from '@/model/enum';
import { ICRUDQ } from '@/model/interface';
import { get, post, del } from './request';

const URL_PATH = `${RULE_BASE_REQUEST_PATH}/alarm`;

class AlarmEventService implements ICRUDQ<any, AlarmEventQueryModel> {
    async create(model: any):Promise<any> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<any> {
        const url = `${URL_PATH}/${modelId}`;
        const res = await get(url);
        return res;
    }

    async update(model: any):Promise<any> {
        // const url = `${URL_PATH}/save`;
        // const params = model.toService();
        // const res = await post(url, params);
        // return res;
        const url = `${URL_PATH}/batchUpdate`;
        return await post(url, model?.toService());
    }

    async delete(model: any):Promise<any> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: AlarmEventQueryModel, page?: number, limit?: number):Promise<any> {
        let url = null;
        switch (query?.subSystemType) {
            case SubSystemType.PARKING:
                url = `${BIBIZ_BASE_REQUEST_PATH}/parkingSystem/alarm/query`;
                break;
            case SubSystemType.FIRE:
                url = `${BIBIZ_BASE_REQUEST_PATH}/fireSystem/alarm/query`;
                break;
            case SubSystemType.CAMERA:
                url = `${BIBIZ_BASE_REQUEST_PATH}/videoSystem/alarm/query`;
                break;
            case SubSystemType.ENVIROMENT:
                url = `${BIBIZ_BASE_REQUEST_PATH}/envSystem/alarm/query`;
                break;
            case SubSystemType.ENERGY:
                url = `${BIBIZ_BASE_REQUEST_PATH}/energySystem/alarm/query`;
                break;
            case SubSystemType.BA:
                url = `${BIBIZ_BASE_REQUEST_PATH}/BASystem/alarm/query`;
                break;
            case SubSystemType.CUSTOMER_FLOW:
                url = `${BIBIZ_BASE_REQUEST_PATH}/custFlowSystem/alarm/query`;
                break;
            case SubSystemType.ELEVATOR:
                url = `${BIBIZ_BASE_REQUEST_PATH}/elevatorSystem/alarm/query`;
                break;
            case SubSystemType.LIGHT:
                url = `${BIBIZ_BASE_REQUEST_PATH}/lightingSystem/alarm/query`;
                break;
            case SubSystemType.ACCESS_CONTROL:
                url = `${BIBIZ_BASE_REQUEST_PATH}/accessControlSystem/alarm/query`;
                break;
            default:
                url = `${URL_PATH}/query`;
                break;
        }
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        return res;
    }

    // async handleEvent(model: any):Promise<any> {
    //     const url = `${URL_PATH}/update`;
    //     const params = {
    //         id: model.id,
    //         comments: model.comments
    //     };
    //     const res = await post(url, params);
    //     return res;
    // }

    async eventDef(eventId:string):Promise<any> {
        const url = `${BI_BASE_REQUEST_PATH}/things/events/detail/${eventId}`;
        const res = await get(url);
        return res;
    }

    async exportExcel(query?: AlarmEventQueryModel):Promise<any> {
        const url = `${URL_PATH}/export`;
        const params = query ? query.toService() : {};
        return await post(url, params);
    }

    // async batchProcess(params: any): Promise<any> {
    //     const url = `${URL_PATH}/batchUpdate`;
    //     return await post(url, params);
    // }
}

export default new AlarmEventService();
