var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-event-drawer" },
    [
      _c(
        "a-spin",
        {
          staticStyle: { overflow: "hidden" },
          attrs: { spinning: _vm.loading }
        },
        [
          _vm.eventModel
            ? _c(
                "div",
                [
                  _c(
                    "jtl-card",
                    { attrs: { title: "告警信息" } },
                    [
                      _vm.eventModel.status === "WCL"
                        ? _c(
                            "a",
                            {
                              staticClass: "jtl-link",
                              attrs: { slot: "extra" },
                              on: { click: _vm.handleClick },
                              slot: "extra"
                            },
                            [_vm._v("去处理")]
                          )
                        : _vm._e(),
                      _c(
                        "a-descriptions",
                        {
                          staticClass: "tab-margin-16",
                          attrs: { bordered: "", column: 2 }
                        },
                        [
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "告警时间" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.eventModel.createdTime
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "告警名称" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.eventModel.eventName) + " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "告警级别" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.eventModel.gradeDesc) + " "
                              )
                            ]
                          ),
                          _c("a-descriptions-item", {
                            attrs: { label: "告警描述" }
                          }),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "告警类型" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.eventModel.levelDesc) + " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "告警位置" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.eventModel.locationName) + " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "设备编号" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.eventModel.facilityCode) + " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "设备名称" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.eventModel.facilityName) + " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "是否恢复" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.eventModel.recover ? "是" : "否") +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "处理状态" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.eventModel.statusDesc) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.eventModel.cameraList &&
                  _vm.eventModel.cameraList.length > 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "jtl-card",
                            { attrs: { title: "实时监控" } },
                            [
                              _c(
                                "a-table",
                                {
                                  staticClass: "parking-table",
                                  attrs: {
                                    "data-source": _vm.eventModel.cameraList,
                                    pagination: false,
                                    bordered: false,
                                    size: "small"
                                  }
                                },
                                [
                                  _c("a-table-column", {
                                    key: "name",
                                    attrs: {
                                      title: "名称",
                                      "data-index": "name"
                                    }
                                  }),
                                  _c("a-table-column", {
                                    key: "status",
                                    attrs: {
                                      title: "状态",
                                      "data-index": "status",
                                      width: 150
                                    }
                                  }),
                                  _c("a-table-column", {
                                    key: "action",
                                    attrs: { title: "", width: 150 },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(text, record) {
                                            return [
                                              record.url
                                                ? _c("a-icon", {
                                                    staticClass: "camera-image",
                                                    attrs: {
                                                      type: "video-camera"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.previewImage(
                                                          record
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      963091874
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.eventModel.imageList &&
                  _vm.eventModel.imageList.length > 0
                    ? _c(
                        "div",
                        [
                          _c("br"),
                          _c(
                            "jtl-card",
                            { attrs: { title: "抓拍图片" } },
                            [
                              _c(
                                "a-table",
                                {
                                  staticClass: "parking-table",
                                  attrs: {
                                    "data-source": _vm.eventModel.imageList,
                                    pagination: false,
                                    bordered: false,
                                    size: "small"
                                  }
                                },
                                [
                                  _c("a-table-column", {
                                    key: "name",
                                    attrs: {
                                      title: "名称",
                                      "data-index": "name"
                                    }
                                  }),
                                  _c("a-table-column", {
                                    key: "createdTime",
                                    attrs: {
                                      title: "时间",
                                      "data-index": "createdTime",
                                      width: 150
                                    }
                                  }),
                                  _c("a-table-column", {
                                    key: "action",
                                    attrs: { title: "", width: 150 },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(text, record) {
                                            return [
                                              record.url
                                                ? _c("a-icon", {
                                                    staticClass: "camera-image",
                                                    attrs: { type: "picture" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.previewImage(
                                                          record
                                                        )
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      622461355
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("br"),
                  _c(
                    "jtl-card",
                    { attrs: { title: "告警记录" } },
                    [
                      _c(
                        "a-table",
                        {
                          staticClass: "parking-table",
                          attrs: {
                            "data-source": _vm.eventModel.relatedEventList,
                            pagination: false,
                            bordered: false,
                            size: "small"
                          }
                        },
                        [
                          _c("a-table-column", {
                            key: "createdTime",
                            attrs: { title: "告警时间", width: 100 },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(text, record) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("dateFormat")(
                                              record.createdTime
                                            )
                                          ) +
                                          "(" +
                                          _vm._s(
                                            _vm._f("dateFromNow")(
                                              record.createdTime
                                            )
                                          ) +
                                          ") "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3073403748
                            )
                          }),
                          _c("a-table-column", {
                            key: "recover",
                            attrs: { title: "是否恢复", width: 50 },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(text, record) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(record.recover ? "是" : "否") +
                                          " "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3077349790
                            )
                          }),
                          _c("a-table-column", {
                            key: "remark",
                            attrs: {
                              title: "告警描述",
                              "data-index": "data",
                              width: 150
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.eventModel.status === "YCL"
                    ? _c(
                        "div",
                        [
                          _c("br"),
                          _c(
                            "jtl-card",
                            { attrs: { title: "处理记录" } },
                            [
                              _c(
                                "a-table",
                                {
                                  staticClass: "parking-table",
                                  attrs: {
                                    "data-source": _vm.eventModel.handleList,
                                    pagination: false,
                                    bordered: false,
                                    size: "small"
                                  }
                                },
                                [
                                  _c("a-table-column", {
                                    key: "createdTime",
                                    attrs: { title: "处理时间", width: 150 },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(text, record) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("dateFormat")(
                                                      record.createdTime
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1047879187
                                    )
                                  }),
                                  _c("a-table-column", {
                                    key: "processMan",
                                    attrs: {
                                      title: "处理人",
                                      "data-index": "processMan",
                                      width: 150
                                    }
                                  }),
                                  _c("a-table-column", {
                                    key: "comments",
                                    attrs: {
                                      title: "处理意见",
                                      "data-index": "comments",
                                      width: 150
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c("jtl-entity-dialog", {
        ref: "handleFormDialog",
        on: {
          dialogOK: function($event) {
            return _vm.handleFormDialog(_vm.eventId)
          }
        }
      }),
      _c(
        "a-modal",
        {
          staticClass: "alarm-event-drawer-image-modal",
          attrs: {
            title: _vm.selectedImgTitle,
            visible: _vm.imageVisible,
            width: "70vw",
            footer: null
          },
          on: {
            ok: function($event) {
              _vm.imageVisible = false
            },
            cancel: function($event) {
              _vm.imageVisible = false
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "100%", "object-fit": "contain" },
            attrs: { src: _vm.selectedImg }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }