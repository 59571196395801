
























































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import AlarmEventService from '@/service/alarm-event';
import { AlarmEventEntityModel } from '@/entity-model/alarm-event-entity';
import { ViewModeType } from '@/model/enum';
import FormDialogComponent from '@/mixins/form-dialog-component';

@Component
export default class AlarmEventDrawer extends BaseComponent {
    @Prop()
    eventId: string;

    loading: boolean = false;
    eventModel: any = {};
    imageVisible: boolean = false;
    selectedImg: string = '';
    selectedImgTitle: string = '';

    @Watch('eventId', { immediate: true, deep: true })
    eventIdChange(newVal: string): void {
        if (!newVal) {
            return;
        }
        this.initAlarmEventDetail(newVal);
    }

    handleClick() {
        const handleModel = new AlarmEventEntityModel([this.eventModel?.id]);
        (this.$refs.handleFormDialog as FormDialogComponent<AlarmEventEntityModel>).dialogOpen(handleModel, AlarmEventService, ViewModeType.UPDATE);
        (this.$refs.handleFormDialog as FormDialogComponent<AlarmEventEntityModel>).dialogTitle = '事件处理';
    }

    previewImage(modelItem) {
        this.selectedImg = modelItem.url;
        this.selectedImgTitle = modelItem.name;
        this.imageVisible = true;
    }

    handleFormDialog(id: string) {
        this.$emit('handled');
        this.initAlarmEventDetail(id);
    }
    initAlarmEventDetail(id: string) {
        this.loading = true;
        AlarmEventService.retrieve(id || this.eventModel?.id).then(res => {
            this.eventModel = res;
            if (this.eventModel.comments) {
                this.eventModel.handleList = [
                    {
                        createdTime: this.eventModel.handleTs,
                        processMan: this.eventModel.processMan,
                        comments: this.eventModel.comments
                    }
                ];
            }
        }).finally(() => {
            this.loading = false;
        });
    }

    // keydown(ev) {
    //     switch (ev.key) {
    //         case 'ArrowLeft':
    //             this.$emit('change', -1);
    //             break;
    //         case 'ArrowRight':
    //             this.$emit('change', 1);
    //             break;
    //     }
    // }
}
